import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
$("header .nav a").eq(0).addClass("active")
var swiper = new Swiper(".index-banner .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    loop:true,
    speed:2000,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.index-banner .swiper-pagination',
        clickable: true,
    },
});

// 产品
$(".section2 .pro-nav a").hover(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".section2 .pic img").eq(index).addClass("active").siblings().removeClass("active");
    $(".section2 .pro-nav a").eq(index).addClass("active").siblings().removeClass("active");
})
$(".section2 .pro-phonenav a:first").addClass("active");
$(".section2 .pro-nav a:first").addClass("active");
$(".section2 .pic img:first").addClass("active");
$(".section2 .pro-phonenav a").click(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".section2 .pic img").eq(index).addClass("active").siblings().removeClass("active");
})

// 应用
$(".section3 .addbg img:first").addClass("active");
$(".section3 .add-nav li:first").addClass("active");
$(".section3 .introduce .content:first").addClass("active");
$(".section3 .add-nav li").hover(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".section3 .addbg img").eq(index).addClass("active").siblings().removeClass("active");
    $(".section3 .introduce .content").eq(index).addClass("active").siblings().removeClass("active");
})
var addswiper = new Swiper('.section3 .mySwiper',{

});


// 滚动新闻
var swiper = new Swiper(".notice .swiper", {
    direction: "vertical",
    loop:true,
    speed:1000,
    autoplay: {
        delay: 3000,
    },
});